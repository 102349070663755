import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const [sidebarClass, setSidebarClass] = useState("");
  return (
    <>
      <IconButton
        className="menu-btn"
        onClick={() => setSidebarClass("side-open")}
      >
        <MenuIcon />
      </IconButton>
      <Sidebar sidebarClass={sidebarClass} setSidebarClass={setSidebarClass} />
      <main className="main">{children}</main>
    </>
  );
};

export default Layout;

import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  search: {
    
    background: "#fff",
    paddingLeft: "20px",
    height: "50px",
    border: "none",
    outline: "none",
    borderRadius: "10px",
    fontSize: "18px",
    width:"100%",
    "&::placeholder": {
      fontStyle: "italic",
      fontSize: "18px",
      color: "#000",
      opacity: "0.3",
    },
  },
  search_box: {
    position: "relative",
    border: "1px solid #DAE4F0",
    borderRadius: "12px",
    margin: "5px"
  },
  search_icon: {
    position: "absolute",
    right: "0px",
    top: "5px",
    background: "transparent",
    color: "#0c4da2",
    border: "none",
    outline: "none",
  },
}));

const SearchInput = ({ searched, requestSearch, myWidth }) => {
  const classes = useStyles();
  return (
    <>
      <Grid conatiner>
        <Grid item sm={12}>
          <div className={classes.search_box} style={{width:myWidth}}>
            <input
              type="text"
              placeholder="Search by IMEI number"
              className={classes.search}
              value={searched}
              onChange={(e) => requestSearch(e.target.value)}
            />
            <button className={classes.search_icon}>
              <SearchIcon style={{ fontSize: "38px" }} />
            </button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchInput;

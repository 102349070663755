import React, { useState, useContext } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { AuthContext } from "../../context/auth";
// import {danyApp} from '../../Firebase';
import firebase from "firebase/app";
import "firebase/auth";
import { Button, Form } from "react-bootstrap";
import Logo from "../../assets/Dany-Logo.png";
import { showToast } from "../../utils/exportScreen";

function Login({history}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setdisplayName] = useState("");

  // const [userName, setUserName] = useState('');

  const [error, setError] = useState("");
  
  //   const [data,setData] = useState({
  //     email:"",
  //     password:"",
  //     error:"null",
  // })
 

  // const handleChange = (e) => {
  //     setData({...data,[e.target.name]:e.target.value})
  // }

  const handleSubmit = (e) => {
    // var email = "muhammdjami45@gmail.com";
    // var password = "nhipoocho@123";
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        user.updateProfile({
          displayName,
        });
        console.log(user);
        
        // ...
        history.push("/devices");

        const userData = async () => {
          await sessionStorage.setItem("Username", user.displayName);
        };
        userData();
      })
      .catch((error) => {
        var errorMessage = error.message;
        setError(errorMessage);
        //showToast("Incorrect email or password.");
      });
  };
  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/devices" />;
  }


  return (
    <div
      className="d-flex"
      style={{
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
        background: "#DAE4F0",

      }}
    >
      <div style={{ background: "#fff", borderRadius: "8px", padding: "3rem",maxWidth:"400px" }}>
        <img src={Logo} alt="Dany Tech" className="mb-4" />
        <h3 className="text-center mb-3">Login</h3>
        <Form className="d-flex flex-column" onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button type="submit" className="fadeIn fourth">
            Login
          </Button>
          
        </Form>
        <p style={{marginTop:"10px",color:"#aa190d"}}>{error && error}</p>
      </div>
      
    </div>
  );
}

export default Login;

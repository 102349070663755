/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "../Softwares/Singlesoftware";
import { getFlatSoftwareApks } from "./devices";

export default function DeviceDetailModal({ isOpen, handleClose, data }) {
  const classes = useStyles();
  const [versions, setVersions] = useState({});

  useEffect(() => {
    if (isOpen && data.apkFiles) {
      getFlatSoftwareApks().then((res) => setVersions(res));
    }
  }, [isOpen]);


  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <div className="p-4">
        <h2 className="mb-4">Device Details</h2>

        {data && (
          <>
            <h5>IMEI: {data.imei}</h5>
            <h5>Mac Address: {data.macAddress}</h5>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>File Name</StyledTableCell>
                    <StyledTableCell align="left">Version</StyledTableCell>
                    <StyledTableCell align="left">File Url</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">
                      Last Update 
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {data.apkFiles ? (
                    data.apkFiles.map((ap, ind) => {

                      return (

                        <StyledTableRow key={ind}>
                          {ap.fileId === "SAfSehLpU10J40zQqkTe"  ? <>
                          
                          </> 
                          : ap.fileId ? <>
                          {console.log(ap.fileId)}
                            <StyledTableCell component="th" scope="row">
                              {versions[ap.fileId]?.name}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {versions[ap.fileId]?.version}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <a href={versions[ap.fileId]?.url}>File Download</a>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {ap.installed !== undefined
                                ? String(ap.installed)
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {ap.lastUpdatedDate} - {ap.lastUpdatedTime}
                            </StyledTableCell></> : <></>
                          }



                        </StyledTableRow>

                      )
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row" colSpan={5}>
                        No APK files found
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </Dialog>
  );
}

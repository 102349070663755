import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    //textAlign: "left",
  },
  add_btn: {
    display: "flex",
    padding: "0px 15px 0px 0px",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "none",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    marginLeft: "auto",
    outline: "none",
    backgroundColor: "#fff",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
  },
  btn_icon: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  welcome: {
    "& > h2": {
      marginTop: "20px",
      marginBottom: "0px",
      fontSize: "32px",
      fontStyle: "italic",
      color: "#0c4da2",
    },
    "& > p": {
      marginTop: "0px",
      fontSize: "14px",
      color: "#585858",
    },
  },
}));
const Header = ({ btn_text, click, click2, block,softwareName, click3 }) => {
  const classes = useStyles();

  return (
    <>
      {/* <Grid
        container
        spacing={1}
        className={classes.root}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item sm={6} className={classes.welcome}>
          <h2>Welcome Mr. John Doe,</h2>
          <p>Lorem ipsum dolor sit amet, consecteturincid</p>
        </Grid>
        <Grid item sm={6} alignItems="flex-end" justify="flex-end">
          <Button onClick={click} style={{ marginLeft: "auto" }}>
            <AddIcon className={classes.btn_icon} />
            {btn_text}
          </Button>
        </Grid>
      </Grid> */}

      <div className="row py-5 header">
        <div className="col-md-6 col-sm-12">
          <div className="d-flex justify-content-start">
            <h2 style={{fontWeight:"500"}}>{softwareName}</h2>
          </div>
        </div>
        
        <div className="col-md-6 col-sm-12">
          <div className="d-flex justify-content-end">
          <Button onClick={click2} variant="danger" style={{backgroundColor:"#aa190d",border:"none",display:block, marginRight:"20px",borderRadius:"5px"}}>Delete</Button>
            <Button style={{display:block, marginRight:"20px"}} onClick={click3}>Edit</Button>
            <Button onClick={click}>
              <AddIcon className={classes.btn_icon} />
              {btn_text}
            </Button>
            
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Header;

import React from "react";
import Layout from "../../../Components/Layout";
import PushApk from "../../../PushApp";
function Notification() {
  return (
    <Layout>
      <h1>Notification</h1>
      <PushApk />
    </Layout>
  );
}

export default Notification;

import { makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SoftwareIcon from "../../../assets/software.png";
import Header from "../../../Components/header";
import Layout from "../../../Components/Layout";
import Software from "../../../Components/software";
import Loading from "../../../Components/Spinner";
import { danyApp } from "../../../Firebase.js";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff",
    borderRadius: "15px",
  },
  softwareCard: {
    background: theme.palette.primary.main,
    margin: "30px",
    borderRadius: "20px",
    padding: "20px",
    height: "fit-content",
    textAlign: "center",
    "& > h3": {
      margin: "0px",
      textTransform: "capitalize",
      fontSize: "32px",
      fontWeight: "600",
      color: "#fff",
    },
    "& > p": {
      margin: "0px",
      color: "#fff",
      fontSize: "18px",
    },
  },
  details: {
    border: "none",
    borderRadius: "10px",
    backgroundColor: "#fff",
    outline: "none",
    color: theme.palette.primary.main,
    fontSize: "16px",
    padding: "5px 20px",
  },
  add_software: {
    background: "transparent",
    borderRadius: "20px",
    border: "1px solid  #80808066",
    margin: "30px",
    minHeight: "150px",
    width: "150px",
    cursor: "pointer",
  },
  add_icon_shape: {
    width: "55px",
    height: "55px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
  add_icon: {
    color: "#fff",
    fontSize: "40px",
    fontWeight: "700",
    margin: "7px",
  },
  add_new_content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    "& > h4": {
      margin: "0px",
      fontSize: "20px",
      color: theme.palette.primary.main,
      fontFamily: "Roboto,sans-serif",
      marginTop: "10px",
    },
  },
}));

const db = danyApp.database();

function Softwares() {
  // const softwares = [
  //     {id:1, name:"software 1", version:"v3.12.234"},
  //     {id:1, name:"software 2", version:"v3.12.234"},
  //     {id:1, name:"software 3", version:"v3.12.234"},
  //     {id:1, name:"software 4", version:"v3.12.234"},
  //     {id:1, name:"software 5", version:"v3.12.234"},
  //     {id:1, name:"software 6", version:"v3.12.234"},
  //     {id:1, name:"software 7", version:"v3.12.234"}
  // ]
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = React.useState();
  const [update, setUpdate] = useState();
  const [open, setOpen] = React.useState(false);

  const [searched, setSearched] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // let files = []
  // db.collection("files").get()
  //     // //   .then(function(querySnapshot) {
  //     // //     querySnapshot.forEach(function(doc) {
  //     // //       // doc.data() is never undefined for query doc snapshots

  //     // //       setData([doc.data(),doc.id])
  //     // //   });
  //     // });
  //     .then(snapshot => {
  //         snapshot.docs.forEach(doc => {
  //             let currentID = doc.id
  //             let dataObj = { ...doc.data(), ['id']: currentID }
  //             let findInstall = doc.data();
  //             console.log(findInstall.install)
  //             files.push(dataObj)
  //         });
  //         setData(files);
  //         setLoading(false);

  //     })

  useEffect(() => {
    setIsLoading(true);
    let software = [];
    var softwares = db.ref("softwares");
    //    .then(snapshot => {
    //        snapshot.docs.forEach(softi => {
    //            let currentID = softi.id
    //            let appObj = {...softi.data(), ['id']: currentID}
    //            software.push(appObj)
    //            software.push(softi.data())
    //        })
    //        setData(software)
    //        console.log(software)
    //    })

    softwares.on("value", (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        var objData = { ...childData, childKey };
        software.push(objData);
      });
      console.log(software);
      setData(software);
      setIsLoading(false);
      //   console.log(snapShotValues, "snapShotValues")
      // console.log(!snapshot.exists(), "beta aaaa")
      // if(snapshot.exists()){
      //     console.log("hello this")
      //     let softwareObject = Object.values(snapshot.val())
      // console.log(softwareObject, "software objects") //software objects

      //     const softwareObjectKeys = Object.keys(snapshot.val())
      //     console.log(softwareObjectKeys, "software object keys")  // software object keys
      //     var objData = {...softwareObject, }
      //     software.push(objData)
      //     console.log(software, "software list")
      //     setData(software)

      // }
    });

    //    softwares.on('child_added', function(data) {
    //     // data.key, data.val().title, data.val().description
    //     console.log(data)
    //   });

    //   softwares.on('child_changed', function(data) {
    //     // data.key, data.val().title, data.val().description
    //     console.log(data)
    //   });

    //   softwares.on('child_removed', function(data) {
    //     // data.key, data.val().title, data.val().description
    //     console.log(data)
    //   });

    //     dbRef.get().then((snapshot) => {
    //   if (snapshot.exists()) {
    //     console.log(snapshot.val());
    //     const array = snapshot.val();
    //     software.push(array)

    //     console.log(software)
    //   } else {
    //     console.log("No data available");
    //   }
    // }).catch((error) => {
    //   console.error(error);
    // });
  }, []);

  console.log(data);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Layout>
      <Header btn_text="Add New Software" click={handleClickOpen} block="none"/>

      <Software handleClose={handleClose} handleOpen={open} />

      {isLoading ? (
        <Loading />
      ) : (
        <div
          className={
            "d-flex flex-row flex-wrap justify-content-evenly " +
            classes.container
          }
        >
          {data.map((raw, index) => {
            return (
              <div className={classes.softwareCard} key={`soft-${index}`}>
                <img src={SoftwareIcon} alt="apk" />
                <h3>{raw.name}</h3>
                <p>{raw.version}</p>
                <Link
                  className={classes.details}
                  to={`/softwares/${raw.childKey}`}
                >
                  view details
                </Link>
              </div>
            );
          })}
          <div className={classes.add_software} onClick={handleClickOpen}>
            <div className={classes.add_new_content}>
              <div className={classes.add_icon_shape}>
                <AddIcon className={classes.add_icon} />
              </div>
              <h4>
                Add New <br />
                Software
              </h4>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Softwares;

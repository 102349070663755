import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { danyApp } from "../../../Firebase";
import Layout from "../../../Components/Layout";
import firebase from "firebase/app";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "../Softwares/Singlesoftware";

const db = danyApp.database();
const db1 = danyApp.firestore();
var batch = db1.batch();
const fieldValue = firebase.firestore.FieldPath.documentId();

function SingleDevice() {
  const classes = useStyles();
  const match = useRouteMatch();
  const id = match.params.id;
  console.log(id);

  const tab = db.ref("Tablets").child(id);
  const dataRef = db1.collection("files");
  const [data, setData] = useState([]);
  const [apkId, setApkId] = useState(null);
  const [apk, setApk] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tabApkFiles = [];
    let apkUpdate = [];
    let apkFilesPusher = [];
    tab.on("value", (snapshot) => {
      const tabData = snapshot.val();
      console.log("🚀 ~ file: SingleDevice.jsx ~ line 48 ~ tab.on ~ tabData", tabData)
      const apkFiles = snapshot.val()?.apkFiles;
      console.log(apkFiles, "apk files on tablets");
      setApkId(apkFiles);

      if (apkFiles) {
        apkFiles.forEach((apk) => {
          const apkDat = apk.fileId;
          console.log(apkDat, "apk file id");
          // Push apk object in apkUpdate Array
          apkUpdate.push(apk);
          // Push apk file id in array
          tabApkFiles.push(apkDat);
        });

        // Find apkFiles by its ids of an Array => tabApkFiles
        console.log(apkUpdate, "apkUpdate");
        dataRef
          .where(fieldValue, "in", tabApkFiles)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              console.log(doc.id, "=>", doc.data(), "search by its document");
              const apkObj = { ...doc.data(), ["fileId"]: doc.id };
              apkFilesPusher.push(apkObj);
            });

            // Match id in objects of array and then Merge two arrays of objects apkUpdate and apkFilePusher
            setTimeout(() => {
              const mergeById = (a1, a2) =>
                a1.map((itm) => ({
                  ...a2.find((item) => item.fileId === itm.fileId && item),
                  ...itm,
                }));
              console.log(mergeById(apkUpdate, apkFilesPusher));
              setApk(mergeById(apkUpdate, apkFilesPusher));
              console.log(apkId, "apkId");
              console.log(apkFilesPusher, "apkFilesPusher");
              setLoading(false);
            }, 3000);
          });
      } else if (!apkFiles) {
        setLoading(false);
      }

      // tabApkFiles.forEach(id => {
      //     console.log(id)
      //     setApkId(id)
      // })
      setData(tabData);
      console.log(tabApkFiles, "tabApkFiles");

      // setTimeout(() => {

      // },3000)
    });
  }, []);

  console.log(data, "State data");
  console.log(apkId, "State apk");
  return (
    <Layout>
      <h2>Device Details</h2>
      {/* {data.map((dat, ind) => {

                return (
                    <div>{dat.macAddress}</div>
                )
            })} */}
      <div>
        <div style={{ float: "left" }}>
          <h3>Device Mac Address</h3>
          <p>{data.macAddress}</p>
        </div>
        <div style={{ float: "right" }}>
          <h3>Device Imei Number</h3>
          <p>{data.imei}</p>
        </div>
      </div>
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          Apk Files
        </Grid>
        <div style={{ height: "70vh", width: "100%" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Package Name</StyledTableCell>
                  <StyledTableCell align="left">Version</StyledTableCell>
                  <StyledTableCell align="left">File Url</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                  <StyledTableCell align="left">
                    Last Update <span>(dd/mm/yy)</span>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!apkId && <>No apk files found</>}
                {loading && <>Loading</>}
                {apk && (
                  <>
                    {apk.map((ap, ind) => {
                      return (
                        <StyledTableRow key={ind}>
                          <StyledTableCell component="th" scope="row">
                            {ap.package_name}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {ap.version}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <a href={ap.url}>File Download</a>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {ap.installed ? <>Installed</> : <>Not Installed</>}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {/* <input type="button" value={ver.install ? "Disable" : "Install"} onClick={() => { handleInstall(); setInstallVal(ver.install)}} name="files"/> */}
                            {ap.lastUpdatedDate} - {ap.lastUpdatedTime}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </Layout>
  );
}

export default SingleDevice;

import React, { useState } from "react";
import { Button } from "react-bootstrap";
import DeviceDetailModal from "./DeviceDetailModal";

export default function ViewDeviceDetailsBtn({row}) {
  const [showDeviceDetailModal, setShowDeviceDetailModal] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState(null);

  return (
    <>
      <Button
        onClick={() => {
          setDeviceDetails(row);
          setShowDeviceDetailModal(true);
        }}
      >
        View Details
      </Button>

      <DeviceDetailModal
        isOpen={showDeviceDetailModal}
        handleClose={() => setShowDeviceDetailModal(false)}
        data={deviceDetails}
      />
    </>
  );
}

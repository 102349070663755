/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, version } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { danyApp } from "../../../Firebase";
import Layout from "../../../Components/Layout";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import Header from "../../../Components/header";
import Dialog from "@material-ui/core/Dialog";
import axios from "axios";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SearchInput from "../../../Components/SearchInput";
import firebase from "firebase/app";
import UpdateSoftware from "./UpdateSoftware";
import { Button, Form } from "react-bootstrap";
import Loading from "../../../Components/Spinner";

const db = danyApp.database();
const dbFire = danyApp.firestore();

const fieldValue = firebase.firestore.FieldPath.documentId();

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#0c4da2",
    fontSize: "18px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "500",
    position: "relative",
    paddingBottom: "40px",

    "&::after": {
      content: '""',
      position: "absolute",
      borderRight: "1px solid grey",
      width: "1px",
      height: "40px",
      right: "0",
      top: "10px",
      opacity: "0.5",
    },
    "&:nth-child(5)": {
      "&::after": {
        display: "none",
      },
    },
    "& > span": {
      color: "grey",
      fontStyle: "italic",
      fontSize: "14px",
    },
  },
  body: {
    fontSize: 16,
    paddingTop: "20px",
    paddingBottom: "20px",
    color: "#585858",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f4f9ff",
    },
  },
}))(TableRow);

export const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
  },

  table: {
    minWidth: 650,
  },
}));

export default function DetailSoftware() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [versionId, setVersionId] = useState([]);
  const [file, setFile] = useState();
  const [versioner, setVersioner] = useState("");
  const [versionName, setVersionName] = useState("");
  const [currentVersion, setCurrentVersion] = useState(null);
  const [packageName, setPackageName] = useState("");
  const [valueId, setValueId] = useState("");
  const [InstallVal, setInstallVal] = useState(null);
  const [myTokens, setMytokens] = useState(null);
  const [click, setClick] = useState(false);
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  const [presentApk, setPresentApk] = useState([]);
  const [checkboxItems, setCheckboxItems] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [updatePackageName, setUpdatePackageName]= useState('')
  const [updateName, setUpdateName]= useState('')
  const [openThree, setOpenThree] = useState(false)
  const [openFour, setOpenFour] = useState(false)
  const match = useRouteMatch();
  const id = match.params.id;
  const soft = db.ref("softwares").child(id);

  const theme = useTheme();
  const requestSearch = (searchedVal) => {
    const filteredRows = rows.filter((row) => {
      return row.col1.toLowerCase().includes(searchedVal);
    });

    setSearched(searchedVal);
  };
  const history = useHistory()
  const softwa = db.ref("softwares");
  useEffect(() => {
    setIsLoading(true);
    soft.on("value", (snapshot) => {
      let singlesoft = [];
      const data = snapshot.val();
      console.log(data);
      var version = data.versions;
      var name = data.name;
      var resti = data.package_name;
      var adddata = data.add_Date;
      setPackageName(resti);

      var single = { name, resti, adddata, ["versions"]: [version] };
      singlesoft.push(single);
      console.log(snapshot.val());
      if (version) {
        var myVersions = Object.values(version);
        console.log(myVersions, "masdkfh ");
        var versionKey = Object.keys(version);
        console.log(versionKey, "version keys");
        setVersionId(versionKey);
      }

      console.log(singlesoft);
      setData(singlesoft);
    });
    console.log(soft, "soft");

    const versionss = soft.child("/versions");
    let versionList = [];
    let trabs = [];
    versionss.on("value", (snapshot) => {
      // if(snapshot.exists()){

      // const dataVal = Object.values(data)
      // console.log(dataVal, "versions")

      console.log(snapshot.exists(), "if snap exist true or false");

      if (snapshot.exists()) {
        const data = snapshot.exportVal();
        const dataValues = Object.keys(data);
        console.log(dataValues, "versions with value");

        const fetchData = dbFire.collection("files");
        fetchData
          .where(fieldValue, "in", dataValues)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              console.log(doc.id, "=>", doc.data(), "search by its document");
              const apkObj = { ...doc.data(), ["fileId"]: doc.id };
              console.log(apkObj, "apk object");
              versionList.push(apkObj);
            });
            setValue(versionList);
            setIsLoading(false);
            console.log(versionList, "version lists");
          });
      }
    });

    

    softwa
      .child(id)
      .child("versions")
      .orderByChild("install")
      .equalTo("true")
      .on("value", function (snapshot) {
        if (snapshot.exists) {
          console.log(snapshot.val());
          let valueName = snapshot.val();

          console.log(valueName, "currentVersionValue");
          setCurrentVersion(valueName);
        }

        snapshot.forEach(function (data) {
          console.log(data, "child value current");
        });
      });

    var myArray = [];

    var checkVersion = db.ref("Tablets");
    console.log(checkVersion, "checkVersion  with  ");
    console.log(versionId, "versionId");
    var checkVersionInstall = db.ref("Tablets");
    checkVersionInstall.on("value", (snapshot) => {
      snapshot.forEach((snap) => {
        const dyy = snap.child("apkFiles");
        const fileId = dyy.val();
        myArray.push(fileId);
      });
    });

    let tablets = [];
    let tokens = [];

    var tab = db.ref("Tablets");

    tab.on("value", (snapshot) => {
      tablets = [];
      tokens = [];
      snapshot.forEach((childSnapshot) => {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        var childDataToken = childSnapshot.val().firebaseToken;
        tokens.push(childDataToken);
        var objData = { ...childData, childKey };
        tablets.push(objData);
      });
      setRows(tablets);
      console.log(tablets, "tablets");
      console.log(tokens, "tokens for tablets");
      setMytokens(tokens);
    });
  }, [refresh]);

  useEffect(() => {
    console.log(rows);
  }, [rows]);

  useEffect(() => {
    console.log(checkboxItems);
  }, [checkboxItems]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddNewFile = async (e) => {
    e.preventDefault();
    const storageRef = danyApp.storage().ref();
    const fileRef = storageRef.child(`apk/${file.name}`);

    await fileRef.put(file).then((res) => {
      console.log("File uploaded", res);
    });
    const docRef = dbFire.collection("files");

    const result = await docRef.add({
      name: file.name,
      url: await fileRef.getDownloadURL(),
      add_Date: Date.now(),
      version: versioner,
      version_Name: versionName,
      package_name: packageName,
    });
    console.log(result.id);

    const refer = db
      .ref("softwares")
      .child(id)
      .child("versions")
      .child(result.id)
      .set({
        name: file.name,
        url: await fileRef.getDownloadURL(),
        add_Date: Date.now(),
        version: versioner,
        version_Name: versionName,
        package_name: packageName,
        install: false,
        id: result.id,
      });

    handleClose();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    db
      .ref("softwares")
      .child(id)
      .update({
        name: updateName,
        package_name: updatePackageName,
      });

    handleCloseTwo();
  };


  const handleOpenModal = () => {
    setModalOpen(true);
    setClick(true);
  };
  const handleOpenModalTwo = () => {
    setOpenTwo(true);
    
  };
  const handleopenModalThree = () => {
    setOpenThree(true);
    
  };
  const handleCloseThree = () => {
    setOpenThree(false);
  }
  const handleopenModalFour = (e) => {
    e.preventDefault();
    setOpenFour(true);
    handleCloseTwo()
  };
  const handleCloseFour = () => {
    setOpenFour(false);
  }
  const handleCloseTwo = () => {
    setOpenTwo(false);
  }


  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDelete = () => {
    softwa.child(id).remove();
    history.push('/softwares')
    handleCloseFour()
    
  }
console.log(data, "data ")
  const SotwareName = data.map(dat => dat.name)
  const SotwarePackage = data.map(dat => dat.resti)
  return (
    <Layout>
      
      <Dialog
        open={openFour}
        onClose={handleCloseFour}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <div className="p-4">
          <h3 className="mb-2 text-center">Are you sure?</h3>
          <p>This will permanently delete this software from your database</p>
          <div className="d-flex justify-content-center">
          <Button variant="danger" style={{padding:"5px 20px"}} onClick={handleUpdate}>Yes</Button>
          <Button onClick={handleCloseFour} style={{padding:"5px 20px", marginLeft:"20px"}}>No</Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openThree}
        onClose={handleCloseThree}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <div className="p-4">
          <h3 className="mb-2 text-center">Are you sure?</h3>
          <p>This will Update this software from your database</p>
          <div className="d-flex justify-content-center">
          <Button variant="danger" style={{padding:"5px 20px"}} onClick={handleDelete}>Yes</Button>
          <Button onClick={handleCloseThree} style={{padding:"5px 20px",marginLeft:"20px"}}>No</Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openTwo}
        onClose={handleCloseTwo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >

        <div className="p-4">
          <h3 className="mb-4 text-center">Edit Software Information</h3>
          <Form className="d-flex flex-column" onSubmit={handleopenModalFour}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder={SotwareName}
                value={updateName}
                onChange={(e) => setUpdateName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder={SotwarePackage}
                value={updatePackageName}
                onChange={(e) => setUpdatePackageName(e.target.value)}
                required
              />
            </Form.Group>

            

            <Button type="submit">Update</Button>
          </Form>
        </div>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <h1>File upload</h1>
        <div>
          <form onSubmit={handleAddNewFile}>
            <input
              type="text"
              placeholder="Version"
              value={versioner}
              onChange={(e) => setVersioner(e.target.value)}
            />
            <input
              type="text"
              placeholder="Version name"
              value={versionName}
              onChange={(e) => setVersionName(e.target.value)}
            />
            <input
              type="file"
              placeholder="Choose File"
              onChange={(e) => setFile(e.target.files[0])}
            />
            <button type="submit" onClick={handleClose}>
              Submit
            </button>
          </form>
        </div> */}

        <div className="p-4">
          <h1 className="mb-4">File upload</h1>
          <Form className="d-flex flex-column" onSubmit={handleAddNewFile}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Version"
                value={versioner}
                onChange={(e) => setVersioner(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Version name"
                value={versionName}
                onChange={(e) => setVersionName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                accept=".apk"
                type="file"
                placeholder="Choose File"
                onChange={(e) => setFile(e.target.files[0])}
                required
              />
            </Form.Group>

            <Button type="submit">Submit</Button>
          </Form>
        </div>
      </Dialog>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {data.map((dat, ind) => {
            return (
              <div key={ind}>
                <Header btn_text="Add New Version" click={handleClickOpen} click2={handleopenModalThree} block="block" softwareName={dat.name} click3={handleOpenModalTwo} />
                {/* <div style={{ float: "right" }}>
                  <p>Current Version</p>
                  {console.log(currentVersion, "meri version values")}
                  {currentVersion && (
                    <>
                      {Object.values(currentVersion).map((vary) => (
                        <p>{vary.version}</p>
                      ))}
                    </>
                  )}
                </div> */}
              </div>
            );
          })}

          <Grid
            container
            className={classes.root}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <div style={{ height: "70vh", width: "100%" }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Version Name</StyledTableCell>
                      
                      <StyledTableCell align="left">File Url</StyledTableCell>
                      
                      <StyledTableCell align="left">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {value && value ? (
                      <>
                        {value.map((ver, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              {ver.version_Name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <a href={ver.url}>Download</a>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {/* <input type="button" value={ver.install ? "Disable" : "Install"} onClick={() => { handleInstall(); setInstallVal(ver.install)}} name="files"/> */}
                              {/* <input name="my-radio" type="radio" value={ver.install === true ? "false" : ver.install === false ? "true" : null} onChange={handleChange} onClick={() => setValueId(ver.fileId)} label="Install"/>
                     <button onClick={handleInstall}>{ver.install === true ? <>Disable</> : ver.install === false ? <>Install</> :null}</button>   */}
                              {/* <input type="radio" value={ver.id} onClick={click ? (e) => setValueId(e.target.value) : null} /> */}

                              <Button
                                onClick={() => {
                                  handleOpenModal();
                                  setValueId(ver.fileId);
                                }}
                              >
                                Update
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>loading</>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <UpdateSoftware
                rows={rows}
                open={modalOpen}
                close={handleCloseModal}
                valueId={valueId}
                refresh={() => setRefresh((s) => !s)}
              />
            </div>
          </Grid>
        </>
      )}
    </Layout>
  );
}

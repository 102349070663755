import React, { createContext, useState, useEffect } from "react";
import { danyApp } from "../Firebase";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    danyApp.auth().onAuthStateChanged((user) => {setCurrentUser(user)
      setPending(false)});
    
  }, []);
  if(pending){
    return <>Loading...</>
  }
  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;

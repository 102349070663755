import React from "react";
import { Devices, Notification, Softwares } from "./utils/exportScreen";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme";
import File from "./File";
import Software from "./Components/software";
import "./App.css";
import Login from "./Screens/Login/login";
import Dashboard from "./Screens/Dashboard/dashboard";
import {AuthProvider} from './context/auth'
import PrivateRoute from "./Screens/Login/PrivateRoutes";
import ErrorPage from "./Components/Nopage";
import Layout from "./Components/Layout";
import DetailSoftware from "./Screens/Dashboard/Softwares/Singlesoftware";
import SingleDevice from "./Screens/Dashboard/Devices/SingleDevice";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { url, path } = useRouteMatch();
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
      <div className="App">
        
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>

         
          <PrivateRoute path="/devices" exact component={Devices} />
            
          <PrivateRoute path="/devices/:id" exact component={SingleDevice} />
            
          <PrivateRoute path="/softwares" exact component={Softwares}/>
            
          <PrivateRoute path="/softwares/:id" exact component={DetailSoftware}/>
            
          <Route path="*" exact>
            404 Error Found
          </Route>
        </Switch>

        <Software />
        <ToastContainer />
      </div>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;

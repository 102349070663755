/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import Logo from "../assets/Dany-Logo.png";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navigation: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    padding: "20px 0px",
  },
  links: {
    fontSize: "20px",
    padding: "10px 0px",
    position: "relative",
    decoration: "none",
    textAlign: "center",
    color:"#0c4da2",
  },
  active: {
    backgroundColor: "#0c4da2",
    color: " #fff",
    textAlign: "center",
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
    padding: "10px 0px",
    zIndex: "1",
    "&$links": {
      "&::after": {
        display: "none",
      },
    },
  },
  footer: {
    position: "absolute",
    textAlign: "center",
    top: "90%",
    left: "0",
    right: "0",
    fontSize: "14px",
    color: "#585858",
  },
  logo:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    "& > img":{
      width:"100%",
      maxWidth:"150px"
    }
  }
}));

function useOutsideAlerter(ref, setSidebarClass) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setSidebarClass("");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Sidebar = ({ sidebarClass, setSidebarClass }) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setSidebarClass);

  return (
    <>
      <div
        ref={wrapperRef}
        className={"sidebar " + sidebarClass}
        onBlur={() => setSidebarClass("")}
      >
        <div className={classes.logo}>
          <img src={Logo} alt="Dany Tech" />
        </div>
        <div className={classes.navigation}>
          <NavLink
            to="/devices"
            activeClassName={classes.active}
            className={classes.links}
            activeStyle={{ color: "#fff" }}
          >
            Devices
          </NavLink>
          <NavLink
            to="/softwares"
            activeClassName={classes.active}
            className={classes.links}
            activeStyle={{ color: "#fff" }}
          >
            Softwares
          </NavLink>
        </div>
        <div className={classes.footer}>
          Design & Develop by <br />
          Designer Dev
        </div>
      </div>
    </>
  );
};

export default Sidebar;

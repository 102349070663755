import React, { useEffect, useState } from "react";
import { danyApp } from "./Firebase";
import { Grid } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import Header from "./Components/header";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
  },

  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#0c4da2",
    fontSize: "18px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "500",
    position: "relative",
    paddingBottom: "40px",

    "&::after": {
      content: '""',
      position: "absolute",
      borderRight: "1px solid grey",
      width: "1px",
      height: "40px",
      right: "0",
      top: "10px",
      opacity: "0.5",
    },
    "&:nth-child(5)": {
      "&::after": {
        display: "none",
      },
    },
    "& > span": {
      color: "grey",
      fontStyle: "italic",
      fontSize: "14px",
    },
  },
  body: {
    fontSize: 16,
    paddingTop: "20px",
    paddingBottom: "20px",
    color: "#585858",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f4f9ff",
    },
  },
}))(TableRow);

const db = danyApp.firestore();
const db1 = danyApp.database();

function PushApk() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = React.useState({});
  const [InstallVal, setInstallVal] = React.useState(null);
  const [myTokens, setMytokens] = React.useState(null);
  const [update, setUpdate] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    let files = [];
    db.collection("files")
      .get()
      // //   .then(function(querySnapshot) {
      // //     querySnapshot.forEach(function(doc) {
      // //       // doc.data() is never undefined for query doc snapshots

      // //       setData([doc.data(),doc.id])
      // //   });
      // });
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          let currentID = doc.id;
          let dataObj = { ...doc.data(), ["id"]: currentID };
          let findInstall = doc.data();
          console.log(findInstall.install);
          files.push(dataObj);
        });
        setData(files);
        setLoading(false);
      });
    let tablets = [];

    var tab = db1.ref("Tablets");
    let tokens = [];
    tab.on("value", (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        var childDataToken = childSnapshot.val().firebaseToken;
        console.log(childDataToken);
        tokens.push(childDataToken);
        console.log(Object.values(tokens));
        let values = Object.values(tokens);
        console.log(values);

        setMytokens(tokens);
        var objData = { ...childData, childKey };
        tablets.push(objData);
        console.log(tablets);
        console.log(childData, "this");
        console.log(childKey);

        //   console.log(childSnapshot)
        // ...
      });
    });
  }, []);

  const handleInstall = async () => {
    await fetch("https://pacific-beyond-65651.herokuapp.com/echo", {
      method: "POST",
      headers: {
        "Content-type": "Application/json",
      },

      body: JSON.stringify({
        data: { body: value, title: "test-value", install: InstallVal },
        tokens: myTokens,
      }),
    })
      .then((response) => {
        console.log(response);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      {/* {success ? (<>
                <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        This is a success alert — <strong>check it out!</strong>
      </Alert>
            </>) : <></>} */}
      {loading && <div>Loading</div>}
      {console.log("clientdata", data)}
      {console.log(value)}
      {console.log(InstallVal)}
      {/* {data.map((file) => {

                return(
                    <div key={file.id}>
                        <p>{file.name}</p>
                        {file.install ? <p>Installed</p> : <p>Not Installed</p>}
                    </div>
                )
            })} */}
      <Header btn_text="Update App" click={handleInstall} />
      {/* <SearchInput searched={searched} requestSearch={requestSearch}/> */}
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <div style={{ height: "70vh", width: "100%" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell># </StyledTableCell>
                  <StyledTableCell align="left">Apk Name</StyledTableCell>
                  <StyledTableCell align="left">Version</StyledTableCell>
                  <StyledTableCell align="left">Version Name</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                  <StyledTableCell align="left">update</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        {index}
                      </StyledTableCell>

                      <StyledTableCell align="left">{row.name}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.version}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.version_Name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <label for={row.install}>
                          {row.install ? <>install</> : <>uninstall</>}
                        </label>
                        <input
                          type="hidden"
                          value={row.install}
                          onChange={handleChange}
                        />
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <label for={row.id}>
                          {row.install ? <>uninstall</> : <>install</>}
                        </label>
                        <input
                          type="radio"
                          value={row.id}
                          label={row.version}
                          onChange={handleChange}
                          name="files"
                          onClick={() =>
                            setInstallVal(row.install ? "false" : "true")
                          }
                        />

                        {/* <input type="button" value={row.install} placeholder="install" label="install"/>
                                            <input type="button" value={row.install} placeholder="install" label="install" hidden/> */}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </div>
  );
}

export default PushApk;

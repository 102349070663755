/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  AppBar
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import axios from "axios";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import SearchInput from "../../../Components/SearchInput";
import { showToast } from "../../../utils/exportScreen";
import ViewDeviceDetailsBtn from "../Devices/ViewDeviceDetailsBtn";
import { StyledTableCell, StyledTableRow, useStyles } from "./Singlesoftware";
import Loading from "../../../Components/Spinner";
import { danyApp } from "../../../Firebase";

const db = danyApp.database();
export default function UpdateSoftware({
  rows,
  open,
  close,
  valueId,
  refresh,
}) {
  const classes = useStyles();
  const [searched, setSearched] = useState("");
  const [data, setData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectDevice, setSelectDevice] = useState([])
  const [showAsc, setShowAsc] = useState(true);
  
  const tablets = db.ref("Tablets");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
  };

  const updateTablets = (data) => {
    return tablets.update(data);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      const searchArray = searched.toLowerCase().split(",");
      const filteredRows = data.map((row) => {
        const lowercaseImei = row.imei.toLowerCase();
        const matches = searchArray.some((str) => lowercaseImei.includes(str));

        if (matches) {
          return { ...row, showRow: true };
        } else {
          return { ...row, showRow: false };
        }
      });
      setData(filteredRows);
    }, 200);

    return () => clearTimeout(id);
  }, [searched, orgData]);

  useEffect(() => {
    console.log(rows);
    setData(rows);
    setOrgData(rows);


  }, [rows]);
 
  useEffect(() => {
    console.log(data);
    setAllSelected(data.every((dt) => dt.isSelected));
    

  }, [data]);


  useEffect(() => {
    if (!open) {
      setData(rows);
      setSearched("");
    }

  }, [open]);



  const onClose = () => {
    close();
  };

  const handleUninstall = () => {
    const { tokens: DeviceTokens, updates } = filterFcmTokens(false);
    const json = {
      data: { body: valueId, title: "test-value", install: "false" },
      tokens: DeviceTokens,
    };
    if (DeviceTokens.length > 0) {
      setIsLoading(true);
      axios
        .post("https://dany-tech.herokuapp.com/echo", json, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then(function (response) {
          showToast(
            `Uninstall notification sent to ${DeviceTokens.length} device(s)`,
            true
          );
          updateTablets(updates)
            .then(() => setIsLoading(false))
            .catch(() => console.error("Unable to update in firebase"));
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
          showToast("Something went wrong");
        });
    } else {
      showToast("Uninstall notification already sent on selected devices");
    }
  };


  const handleInstall = () => {
    const { tokens: DeviceTokens, updates } = filterFcmTokens(true);
    const json = {
      data: { body: valueId, title: "test-value", install: "true" },
      tokens: DeviceTokens,
    };
    if (DeviceTokens.length > 0) {
      setIsLoading(true);
      axios
        .post("https://dany-tech.herokuapp.com/echo", json, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then(function (response) {
          showToast(
            `Install notification sent to ${DeviceTokens.length} device(s)`,
            true
          );
          updateTablets(updates);
        })
        .catch(function (error) {
          console.log(error);
          showToast("Something went wrong")
            .then(() => setIsLoading(false))
            .catch(() => console.error("Unable to update in firebase"));
        })
        .finally(() => setIsLoading(false));
    } else {
      showToast("Install notification already sent on selected devices");
    }
  };

  const selectAll = (e) => {
    setAllSelected(e.target.checked);
    setData(
      produce((draft) => {
        draft.forEach((_, i) => {
          if (draft[i].showRow) draft[i].isSelected = e.target.checked;
        });
      })
      
    );
    console.log(data)
  };

  const filterFcmTokens = (install) => {
    let tokens = [];
    let updates = {};
    if (install) {
      let apkIndex = null;
      data.forEach((i) => {
        const isAlreadyInstalled =
          Array.isArray(i?.apkFiles) &&
          i?.apkFiles?.some((j, k) => {
            if (j.fileId === valueId && j.installed === false) {
              console.log(k);
              apkIndex = k;
            }
            return j.fileId === valueId && j.installed;
          });

        if (i.isSelected && !isAlreadyInstalled) {
          tokens.push(i.firebaseToken);
          if (apkIndex !== null) {
            updates[`${i.imei}/apkFiles/${apkIndex}/webInstalled`] = true;
          } else {
            const index = i?.apkFiles?.length ?? 0;
            updates[`${i.imei}/apkFiles/${index}/fileId`] = valueId;
            updates[`${i.imei}/apkFiles/${index}/webInstalled`] = true;
            updates[`${i.imei}/apkFiles/${index}/installed`] = false;
            updates[`${i.imei}/apkFiles/${index}/lastUpdatedDate`] = "";
            updates[`${i.imei}/apkFiles/${index}/lastUpdatedTime`] = "";
          }
        }
      });
    } else {
      let apkIndex = null;
      data.forEach((i) => {
        const isAlreadyUnInstalled =
          (Array.isArray(i?.apkFiles) &&
            i?.apkFiles?.some((j, k) => {
              if (j.fileId === valueId && j.installed === true) {
                console.log(k);
                apkIndex = k;
              }
              return j.fileId === valueId && j.installed === false;
            })) ??
          true;

        if (i.isSelected && !isAlreadyUnInstalled) {
          tokens.push(i.firebaseToken);
          if (apkIndex !== null) {
            updates[`${i.imei}/apkFiles/${apkIndex}/webInstalled`] = false;
          } else {
            const index = i?.apkFiles?.length ?? 0;
            updates[`${i.imei}/apkFiles/${index}/fileId`] = valueId;
            updates[`${i.imei}/apkFiles/${index}/webInstalled`] = false;
            updates[`${i.imei}/apkFiles/${index}/installed`] = true;
            updates[`${i.imei}/apkFiles/${index}/lastUpdatedDate`] = "";
            updates[`${i.imei}/apkFiles/${index}/lastUpdatedTime`] = "";
          }
        }
      });
    }
    
    console.log(tokens);
    console.log(updates);
    console.log(Object.keys(updates).length);
    return { tokens, updates };
  };


  const filtertwo = () => {

    let secondToken = []

      data.forEach((i) => {
        // const isAlreadyInstalled =
        //   Array.isArray(i?.apkFiles) &&
        //   i?.apkFiles?.some((j, k) => {
        //     if (j.fileId === valueId && j.installed === false) {
        //       console.log(k);
        //       apkIndex = k;
        //     }
        //     return j.fileId === valueId && j.installed;
        //   });
     
     
      const apkFileindex = i.apkFiles ? i.apkFiles : null
      
      
      
        const myToken = i.firebaseToken

        // const mapApk = apkFileindex !== null ? apkFileindex.map(v => ({...v, mytoken: myToken})) : null
        // console.log(mapApk)
        if (i.isSelected) {
          // if(i.apkFiles == !null || i.apkFiles == !undefined){
          //   i.apkFiles.forEach((u) => {
              
          //   })
          // }
          secondToken.push(myToken)
          let myObj = i.apkFiles == !null && i.apkFiles.find(o => o.fileId === valueId) 
      console.log(myObj);
     
          // if (apkIndex !== null) {
          //   updates[`${i.imei}/apkFiles/${apkIndex}/webInstalled`] = true;
          // } else {
          //   const index = i?.apkFiles?.length ?? 0;
          //   updates[`${i.imei}/apkFiles/${index}/fileId`] = valueId;
          //   updates[`${i.imei}/apkFiles/${index}/webInstalled`] = true;
          //   updates[`${i.imei}/apkFiles/${index}/installed`] = false;
          //   updates[`${i.imei}/apkFiles/${index}/lastUpdatedDate`] = "";
          //   updates[`${i.imei}/apkFiles/${index}/lastUpdatedTime`] = "";
          // }
        }
      });

      
      const json = {
        data: { body: valueId, title: "test-value", install: "true" },
        tokens: secondToken,
      };
      if (secondToken.length > 0) {
        setIsLoading(true);
        axios
          .post("https://dany-tech.herokuapp.com/echo", json, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then(function (response) {
            showToast(
              `Install notification sent to ${secondToken.length} device(s)`,
              true
            );
            
          })
          .catch(function (error) {
            console.log(error);
            showToast("Something went wrong")
              .then(() => setIsLoading(false))
              .catch(() => console.error("Unable to update in firebase"));
          })
          .finally(() => setIsLoading(false));
      } else {
        showToast("Install notification already sent on selected devices");
      }
    
    console.log(secondToken);
    
    
  };

  const filtertwoUninstall = () => {

    let secondToken = []

      data.forEach((i) => {
        // const isAlreadyInstalled =
        //   Array.isArray(i?.apkFiles) &&
        //   i?.apkFiles?.some((j, k) => {
        //     if (j.fileId === valueId && j.installed === false) {
        //       console.log(k);
        //       apkIndex = k;
        //     }
        //     return j.fileId === valueId && j.installed;
        //   });
      const apkFileindex = i.apkFiles ? i.apkFiles : null
        const myToken = i.firebaseToken

        // const mapApk = apkFileindex !== null ? apkFileindex.map(v => ({...v, mytoken: myToken})) : null
        // console.log(mapApk)
        if (i.isSelected) {
          // if(i.apkFiles == !null || i.apkFiles == !undefined){
          //   i.apkFiles.forEach((u) => {
              
          //   })
          // }
          secondToken.push(myToken)
          let myObj = i.apkFiles == !null && i.apkFiles.find(o => o.fileId === valueId) 
      console.log(myObj);
     
          
        }
      });

      function unInstall(){
        const json = {
          data: { body: valueId, title: "test-value", install: "false" },
          tokens: secondToken,
        };
        if (secondToken.length > 0) {
          setIsLoading(true);
          axios
            .post("https://dany-tech.herokuapp.com/echo", json, {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            })
            .then(function (response) {
              showToast(
                `Uninstall notification sent to ${secondToken.length} device(s)`,
                true
              );
              
            })
            .catch(function (error) {
              console.log(error);
              showToast("Something went wrong")
                .then(() => setIsLoading(false))
                .catch(() => console.error("Unable to update in firebase"));
            })
            .finally(() => setIsLoading(false));
        } else {
          showToast("Uninstall notification already sent on selected devices");
        }
      }
      unInstall()
      setTimeout(() => {
        unInstall()
      }, 1000);
    
    console.log(secondToken);
    
    
  };
  
  console.log(selectedData)
  console.log(selectedData.length)
  const selectedDevices = selectedData.length;
  const CountDevices = data && data.length;



  const sortedAscending = [...data].sort((a, b) => {
    return a.imei - b.imei;
  });
  console.log(sortedAscending)
  const sortedDescending = [...data].sort((a, b) => {
    return b.imei - a.imei;
  });
  console.log(sortedDescending)

  let TokenCountSelectedDevice = []
  function SelectedDeviceCount(){
    data.forEach((i) => {
      const myToken = i.firebaseToken
      if (i.isSelected) {
        TokenCountSelectedDevice.push(myToken)
      }
    });
  }
  SelectedDeviceCount()
  console.log(TokenCountSelectedDevice.length, "this token")

  //   const sortAscending = () => {
  //     let sortedData = data.sort((a, b) => a - b)
  //     setData(sortedData)
  // }
  // const sortDescending = () => {
  //     let sortedData = data.sort((a, b) => b - a)
  //     setData(sortedData)
  // }



  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      fullWidth={true}
      maxWidth={"lg"}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      
    >
     <div className="d-flex flex-row justify-content-between align-items-center mt-3 p-2">
     <SearchInput searched={searched} requestSearch={requestSearch} myWidth="900px" />
     <div className="d-flex flex-row justify-content-between">
     <DropdownButton id="dropdown-basic-button" title="Install" style={{marginRight:"20px"}}>
     
    <Dropdown.Item class="dropdown-item" href="#" onClick={handleInstall}>With Checks</Dropdown.Item>
    <Dropdown.Item class="dropdown-item" href="#" onClick={filtertwo}>Without Checks</Dropdown.Item>
  
     
 
</DropdownButton>
<DropdownButton id="dropdown-basic-button" title="Uninstall">
          <Dropdown.Item class="dropdown-item" href="#" onClick={handleUninstall}>With Checks</Dropdown.Item>
    <Dropdown.Item class="dropdown-item" href="#" onClick={filtertwoUninstall}>Without Checks</Dropdown.Item>
</DropdownButton>
     </div>
      
     </div>
     
    
     
      
      {isLoading && <Loading />}
      <Grid
        container={true}
        className={classes.root}
        direction="row"
        justify="space-between"
        alignItems="center"
        style={isLoading ? { visibility: "hidden" } : {}}
      >
        <div className="d-flex justify-content-end w-100">
          
          
        </div>
        <div className="d-flex justify-content-end w-100">
         
          
        </div>

        <div style={{ height: "70vh", width: "100%" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    #
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <input
                      style={{ marginRight: "1rem" }}
                      type="checkbox"
                      onChange={selectAll}
                      checked={allSelected}
                    />
                    Select
                  </StyledTableCell>
                  <StyledTableCell align="center">IMEI Number
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button variant="outlined" color="primary" style={{marginLeft:"10px",padding:"0px"}} onClick={() => setShowAsc(!showAsc)}><UnfoldMoreIcon style={{ color:"primary"}}/></Button>
                      
                    </ButtonGroup>
                  </StyledTableCell>
                  <StyledTableCell align="center">Mac Address</StyledTableCell>
                  

                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {showAsc ? data.map(
                  (row, ind) =>
                    row.showRow && (
                      <StyledTableRow key={ind}>
                        <StyledTableCell align="center" className="table_cell">{ind + 1}</StyledTableCell>
                        <StyledTableCell align="center">
                          <input
                            type="checkbox"
                            checked={row.isSelected || false}
                            value={ind}
                            onChange={(e) => {
                              setData(
                                produce((draft) => {
                                  draft[ind].isSelected = e.target.checked
                                    ? true
                                    : false;
                                    
                                })
                                
                              );
                            }}
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {row.imei}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.macAddress}
                        </StyledTableCell>
                        

                        <StyledTableCell align="center">
                          <ViewDeviceDetailsBtn row={row} />
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                ) : sortedDescending.map(
                  (row, ind) =>
                    row.showRow && (
                      <StyledTableRow key={ind}>
                        <StyledTableCell align="center" className="table_cell">{ind + 1}</StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          <input
                            type="checkbox"
                            checked={row.isSelected || false}
                            value={ind}
                            onChange={(e) => {
                              setData(
                                produce((draft) => {
                                  draft[ind].isSelected = e.target.checked
                                    ? true
                                    : false;
                                })
                              );
                            }}
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {row.imei}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.macAddress}
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">{row.col3}</StyledTableCell> */}

                        <StyledTableCell align="center">
                          <ViewDeviceDetailsBtn row={row} />
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
      <div className="appbar">
     <h4 >Total Devices: <span>{CountDevices}</span></h4>
      <h4 >Selected Devices: <span>{TokenCountSelectedDevice.length}</span></h4>
     </div>
    </Dialog>
  );
}

import React from "react";
import { Spinner } from "react-bootstrap";

export default function Loading() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100%" }}
    >
      <Spinner
        animation="border"
        style={{ fontSize: "18px", color: "#104EA2" }}
      />
    </div>
  );
}

import Softwares from "../Screens/Dashboard/Softwares/softwares";
import Devices from "../Screens/Dashboard/Devices/devices";
import Notification from "../Screens/Dashboard/Notification/notification";
import { toast } from "react-toastify";

export const showToast = (msg, success = false) => {
  if (success === true) {
    toast.success(msg, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.error(msg, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export { Softwares, Devices, Notification };

import React, { useEffect, useState } from "react";
import { danyApp } from "./Firebase";

const db = danyApp.firestore();
const db1 = danyApp.database();

const File = () => {
  const [file, setFile] = useState();
  const [version, setVersion] = useState("");
  const [versionName, setVersionName] = useState("");

  useEffect(() => {
    fetch(
      "https://firestore.googleapis.com/v1/projects/dany-tech-3722f/databases/(default)/documents/softwares/"
    )
      .then((response) => {
        console.log(response.json());
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const metadata = {
    //     customMetadata:{
    //         'version': version,
    //     'version Name': versionName,
    //     }

    // }
    const storageRef = danyApp.storage().ref();
    const fileRef = storageRef.child(`apk/${file.name}`);

    await fileRef.put(file).then((res) => {
      console.log("File uploaded", res);
    });
    const docRef = db.collection("files");

    // await (await docRef.doc(file.name)).set({
    //     id:"1"
    // })
    // await docRef.doc(file.name).set({
    //     id:"2",
    //     name:
    // })

    const result = await docRef.add({
      name: file.name,
      url: await fileRef.getDownloadURL(),
      add_Date: Date.now(),
      version: version,
      version_Name: versionName,
      package_name: "",
      install: false,
    });
    console.log(result.id);
    const myVersions = {
      version: "v1",
      version_Name: "test version",
      package_name: docRef.doc(),
      install: false,
    };

    // const softwareDoc =  db1.ref('softwares/'+ 123)

    //     function writeNewPost() {
    //         // A post entry.
    //         var postData = {
    //             version: "v1",
    //             version_Name: "test version",
    //             package_name: "test-route",
    //             install: false,
    //         };

    //         // Get a key for a new Post.
    //         var newPostKey = danyApp.database().ref('softwares/'+ 123).child('versions').push().key;

    //         // Write the new post's data simultaneously in the posts list and the user's post list.
    //         var updates = {};
    //         updates['/softwares/' + 123] = postData;
    //         updates['softwares'+ 123 + '/versions/' + newPostKey] = postData;

    //         return danyApp.database().ref().update(updates);
    //       }

    //       writeNewPost()

    const refer = db1
      .ref("softwares")
      .child("-MdY7Rexk1d5M_fNp7UZ")
      .child("versions");

    refer.push({
      name: file.name,
      url: await fileRef.getDownloadURL(),
      add_Date: Date.now(),
      version: version,
      version_Name: versionName,
      install: false,
    });
  };

  return (
    <div>
      <h1>File upload</h1>
      <div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Version"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
          />
          <input
            type="text"
            placeholder="Version name"
            value={versionName}
            onChange={(e) => setVersionName(e.target.value)}
          />
          <input
            type="file"
            placeholder="Choose File"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default File;

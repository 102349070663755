/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Layout from "../../../Components/Layout";
import { Grid } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import Header from "../../../Components/header";
import SearchInput from "../../../Components/SearchInput";
import { danyApp } from "../../../Firebase";
import Loading from "../../../Components/Spinner";
import DeviceDetailModal from "./DeviceDetailModal";

import { Button } from "react-bootstrap";
import ViewDeviceDetailsBtn from "./ViewDeviceDetailsBtn";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
  },

  table: {
    minWidth: 650,
  },
}));

const originalRows = [
  {
    id: 1,
    col1: "354069644334341",
    col2: "02 / 06 / 2019",
    col3: "Software 1, Software 3",
  },
  {
    id: 2,
    col1: "354069644334342",
    col2: "02 / 06 / 2019",
    col3: "Software 1",
  },
  {
    id: 3,
    col1: "354069644334343",
    col2: "02 / 06 / 2019",
    col3: "Software 3",
  },
];

const columns = [
  { field: "col1", headerName: "Column 1", width: 150 },
  { field: "col2", headerName: "Column 2", width: 150 },
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#0c4da2",
    fontSize: "18px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "500",
    position: "relative",
    paddingBottom: "40px",

    "&::after": {
      content: '""',
      position: "absolute",
      borderRight: "1px solid grey",
      width: "1px",
      height: "40px",
      right: "0",
      top: "10px",
      opacity: "0.5",
    },
    "&:nth-child(5)": {
      "&::after": {
        display: "none",
      },
    },
    "& > span": {
      color: "grey",
      fontStyle: "italic",
      fontSize: "14px",
    },
  },
  body: {
    fontSize: 16,
    paddingTop: "20px",
    paddingBottom: "20px",
    color: "#585858",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f4f9ff",
    },
  },
}))(TableRow);

export const getFlatSoftwareApks = () => {
  return new Promise((resolve, reject) => {
    danyApp
      .database()
      .ref("/softwares")
      .once("value")
      .then((snapshot) => {
        const data = snapshot.val();
        const apps = Object.keys(data);
        const versions = apps.reduce(
          (prev, now) =>
            data[now].versions ? { ...prev, ...data[now].versions } : prev,
          {}
        );

        resolve(versions);
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: devices.jsx ~ line 121 ~ getFlatSoftwareApks ~ err",
          err
        );
        resolve(err);
      });
  });
};

const db = danyApp.database();
function Devices() {
  const [isLoading, setIsLoading] = useState(false);
  const [allRows, setAllRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  const classes = useStyles();

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      const searchArray = searched.toLowerCase().split(",");
      const filteredRows = allRows.filter((row) => {
        const lowercaseImei = row.imei.toLowerCase();
        const matches = searchArray.some((str) => lowercaseImei.includes(str));
        return matches;
      });

      setRows(filteredRows);
    }, 200);

    return () => clearTimeout(id);
  }, [searched, allRows]);

  useEffect(() => {
    setIsLoading(true);
    let tablets = [];
    let tokens = [];
    var tab = db.ref("Tablets");

    tab.on("value", (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        var childDataToken = childSnapshot.val().firebaseToken;
        console.log(childDataToken);
        tokens.push(childDataToken);
        console.log(tokens);
        var objData = { ...childData, childKey };
        tablets.push(objData);
        console.log(tablets);
        console.log(childData, "this");
        console.log(childKey);
      });
      setAllRows(tablets);
      setRows(tablets);
      setIsLoading(false);
    });
  }, []);

  return (
    <Layout>
      <Header btn_text="Add new Device" block="none"/>
      <SearchInput searched={searched} requestSearch={requestSearch} myWidth="100%" />
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <div className="overflow-container">
          {isLoading ? (
            <Loading />
          ) : (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell># </StyledTableCell>
                    <StyledTableCell align="left">IMEI Number</StyledTableCell>
                    <StyledTableCell align="left">Mac Address</StyledTableCell>
                    {/* <StyledTableCell align="left">
                      Install Status
                    </StyledTableCell> */}

                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, ind) => (
                    <StyledTableRow key={ind}>
                      <StyledTableCell component="th" scope="row">
                        {ind + 1}
                      </StyledTableCell>

                      <StyledTableCell align="left">{row.imei}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.macAddress}
                      </StyledTableCell>
                      {/* <StyledTableCell align="left">{row.webInstalled}</StyledTableCell> */}

                      <StyledTableCell align="left">
                        <ViewDeviceDetailsBtn row={row} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </Grid>
    </Layout>
  );
}

export default Devices;

import React, { useState } from "react";
import { danyApp } from "../Firebase";
import Dialog from "@material-ui/core/Dialog";
import { Button, Form } from "react-bootstrap";

const db = danyApp.firestore();
const Software = ({ handleOpen, handleClose }) => {
  const [file, setFile] = useState();
  const [version, setVersion] = useState("");
  const [packageName, setPackageName] = useState("");
  const [softName, setSoftName] = useState("");

  const [versionName, setVersionName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const docRef = danyApp.database();
    function writeUserData() {
      var uploadSoft = docRef.ref("softwares");

      uploadSoft.push({
        name: softName,
        add_Date: Date.now(),
        package_name: packageName,
        versions: [{}],
      });
    }
    writeUserData();
    handleClose();
  };

  return (
    <Dialog
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="p-4">
        <h1 className="mb-4">Software upload</h1>
        <Form className="d-flex flex-column" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Software Name"
              value={softName}
              onChange={(e) => setSoftName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Package name"
              value={packageName}
              onChange={(e) => setPackageName(e.target.value)}
              required
            />
          </Form.Group>
          <Button type="submit">Submit</Button>
        </Form>
      </div>
    </Dialog>
  );
};

export default Software;

import firebase from "firebase";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAzRfDBXhYbGy7ShdacTA5EbRjSpnLNvVg",
  authDomain: "danylauncherapp.firebaseapp.com",
  databaseURL: "https://danylauncherapp-default-rtdb.firebaseio.com",
  projectId: "danylauncherapp",
  storageBucket: "danylauncherapp.appspot.com",
  messagingSenderId: "955356934576",
  appId: "1:955356934576:web:c7e205d16aba01323da675",
};
// Initialize Firebase
export const danyApp = firebase.initializeApp(firebaseConfig);

import React from "react";
import Layout from "../../Components/Layout";

function Dashboard() {
  return (
    <Layout>
      <h1>Dashboard</h1>
    </Layout>
  );
}

export default Dashboard;
